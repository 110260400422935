exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-awards-and-certifications-index-js": () => import("./../../../src/pages/about-us/awards-and-certifications/index.js" /* webpackChunkName: "component---src-pages-about-us-awards-and-certifications-index-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-careers-apply-js": () => import("./../../../src/pages/careers/apply.js" /* webpackChunkName: "component---src-pages-careers-apply-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-life-at-hyscaler-js": () => import("./../../../src/pages/careers/life-at-hyscaler.js" /* webpackChunkName: "component---src-pages-careers-life-at-hyscaler-js" */),
  "component---src-pages-careers-our-journey-js": () => import("./../../../src/pages/careers/our-journey.js" /* webpackChunkName: "component---src-pages-careers-our-journey-js" */),
  "component---src-pages-case-studies-single-js": () => import("./../../../src/pages/case-studies/single.js" /* webpackChunkName: "component---src-pages-case-studies-single-js" */),
  "component---src-pages-contact-us-business-enquiry-js": () => import("./../../../src/pages/contact-us/business-enquiry.js" /* webpackChunkName: "component---src-pages-contact-us-business-enquiry-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-contact-us-request-for-proposal-js": () => import("./../../../src/pages/contact-us/request-for-proposal.js" /* webpackChunkName: "component---src-pages-contact-us-request-for-proposal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-single-js": () => import("./../../../src/pages/insights/single.js" /* webpackChunkName: "component---src-pages-insights-single-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-single-js": () => import("./../../../src/pages/services/single.js" /* webpackChunkName: "component---src-pages-services-single-js" */),
  "component---src-pages-single-js": () => import("./../../../src/pages/single.js" /* webpackChunkName: "component---src-pages-single-js" */),
  "component---src-templates-archive-index-js": () => import("./../../../src/templates/archive-index.js" /* webpackChunkName: "component---src-templates-archive-index-js" */),
  "component---src-templates-case-studies-index-js": () => import("./../../../src/templates/case-studies-index.js" /* webpackChunkName: "component---src-templates-case-studies-index-js" */),
  "component---src-templates-insights-index-js": () => import("./../../../src/templates/insights-index.js" /* webpackChunkName: "component---src-templates-insights-index-js" */)
}

